import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Stack, Chip, Typography } from '@mui/material'
import { includes } from 'lodash'
import I18n from 'i18next'
import PropTypes from 'prop-types'

import { useGetIndicators } from '@api'
import { validateData } from '@pages/im/actions/common'

import {
  refreshMap,
  updateLocationDataAndRefresh,
  enablePolygonTools,
  updateManuallyEnteredPolygon,
} from '@pages/im/actions/location'

import Buttons from '../shared/buttons/buttons'
import Inputs from './components/inputs/inputs.jsx'
import Map from './components/map/map.jsx'
import AffectedPolygonArea from './components/affected-polygon-area/affected-polygon-area'

import * as styles from './location-im-form.scss'
import { ImFormModeEnum, ImTypeEnum } from '@enums'

const LocationIMForm = (props) => {
  const [state, setState] = useState<{
    initialPoints?: number
    initialArea?: number
    points?: number
    area?: number
  }>({})

  const {
    refetch: fetchIndicators,
    isFetching: isFetchingIndicators,
    data: indicators,
  } = useGetIndicators(ImTypeEnum.LOCATION)

  useEffect(() => {
    fetchIndicators()
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    const { validateData, saveMessage } = props
    const { valid } = validateData('LOCATION')
    if (valid) {
      saveMessage()
    }
  }

  const showNotice = () => {
    return Object.keys(props.errors).length > 0 || props.hasErrors
  }

  const disableSubmit = () => {
    const { inProgress, polygonToolsEnabled } = props
    return inProgress || polygonToolsEnabled
  }

  const handleBackClick = () => {
    props.refreshMap()
    props.onBack()
  }

  const handleResetClick = () => {
    setState({ ...state, points: state.initialPoints, area: state.initialArea })
    props.updateLocationDataAndRefresh(
      { editable: true, draggable: true },
      { refreshMap: { data: true, bounds: false } }
    )
  }

  const handleSaveClick = () => {
    props.enablePolygonTools(false)
    setState({ ...state, initialPoints: state.points, initialArea: state.points })
    props.updateManuallyEnteredPolygon(state.points)
  }

  const onPolygonChange = (polygon) => {
    const points = polygon
      .getPath()
      .getArray()
      .map((latLng) => {
        const { lat: latitude, lng: longitude } = latLng.toJSON()
        return { latitude, longitude }
      })
    const area = google.maps.geometry.spherical.computeArea(polygon.getPath())
    setState({ ...state, points, area })
  }

  const onPolygonComplete = (polygon) => {
    const points = polygon
      .getPath()
      .getArray()
      .map((latLng) => {
        const { lat: latitude, lng: longitude } = latLng.toJSON()
        return { latitude, longitude }
      })
    const area = google.maps.geometry.spherical.computeArea(polygon.getPath())
    setState({ ...state, initialPoints: points, initialArea: area })
    props.updateManuallyEnteredPolygon(points)
  }

  const enabledPolygonTools = () => {
    return props.isPolygon && props.polygonToolsEnabled
  }

  const pageHeader = () => {
    switch (props.formMode) {
      case ImFormModeEnum.FORM_MODE_CREATE: {
        return I18n.t('indicator_message.headers.location')
      }
      case ImFormModeEnum.FORM_MODE_COPY: {
        return I18n.t('indicator_message.headers.location_copy')
      }
      default:
        return I18n.t('indicator_message.headers.location_edit')
    }
  }

  const errorMessage = () => {
    return props.formMode === ImFormModeEnum.FORM_MODE_CREATE
      ? I18n.t('common.messages.unable_to_create')
      : I18n.t('common.messages.unable_to_update_im')
  }

  const submitButtonLabel = () => {
    return includes([ImFormModeEnum.FORM_MODE_CREATE, ImFormModeEnum.FORM_MODE_COPY], props.formMode)
      ? I18n.t('indicator_message.buttons.create_only_im')
      : I18n.t('indicator_message.buttons.update_im')
  }

  const { polygonArea, polygonToolsEnabled } = props

  return (
    <Stack sx={{ pt: 2, px: 3 }} className={styles.location}>
      <Typography variant="h4" component="h1" gutterBottom>
        {pageHeader()}
        {showNotice() && (
          <Chip color="error" label={errorMessage()} sx={{ borderRadius: '4px', ml: 2, fontSize: '1.1rem' }} />
        )}
      </Typography>
      <Stack flex={1} component={'form'} onSubmit={handleSubmit} noValidate>
        <Inputs indicators={indicators} indicatorsLoading={isFetchingIndicators} />
        {polygonArea ? <AffectedPolygonArea area={polygonArea} /> : null}
        <Box flex={1} sx={{ mt: 2 }}>
          <Map
            onPolygonChange={onPolygonChange}
            onPolygonComplete={onPolygonComplete}
            disableMapTopRightControls={polygonToolsEnabled}
          />
        </Box>
        <Buttons
          submit={{ disabled: disableSubmit(), label: submitButtonLabel() }}
          back={{ onClick: handleBackClick, disabled: polygonToolsEnabled }}
          cancel={{ onClick: props.onCancel, disabled: polygonToolsEnabled }}
          reset={{ onClick: handleResetClick }}
          save={{ onClick: handleSaveClick }}
          enabledPolygonTools={enabledPolygonTools()}
        />
      </Stack>
    </Stack>
  )
}

const mapStateToProps = (rootState) => {
  const {
    formMode,
    location: {
      errors,
      data: { polygonArea, isPolygon, polygonPoints },
      polygonToolsEnabled,
    },
    save: { inProgress, hasErrors },
  } = rootState.indicatorMessage
  return {
    formMode,
    errors,
    inProgress,
    hasErrors,
    polygonArea,
    isPolygon,
    polygonPoints,
    polygonToolsEnabled,
  }
}

const mapDispatchToProps = {
  validateData,
  refreshMap,
  updateLocationDataAndRefresh,
  updateManuallyEnteredPolygon,
  enablePolygonTools,
}

LocationIMForm.propTypes = {
  errors: PropTypes.object,
  polygonPoints: PropTypes.arrayOf(PropTypes.object),
  inProgress: PropTypes.bool,
  hasErrors: PropTypes.bool,
  isPolygon: PropTypes.bool,
  polygonToolsEnabled: PropTypes.bool,
  enablePolygonTools: PropTypes.func.isRequired,
  polygonArea: PropTypes.number,
  validateData: PropTypes.func.isRequired,
  saveMessage: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  refreshMap: PropTypes.func.isRequired,
  updateLocationDataAndRefresh: PropTypes.func.isRequired,
  updateManuallyEnteredPolygon: PropTypes.func.isRequired,
  formMode: PropTypes.string,
  // react-router props
  history: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationIMForm)
