import { useQuery } from '@tanstack/react-query'
import { stringify } from 'qs'
import set from 'lodash/set'
import { HistoryFormValues, GetHistoryMessagesSchema, HistoryMessagesData } from '@types'
import { fetch, displayError } from '@services'
import { camelizeKeys } from 'humps'
import { ImTypeEnum, QueryStrategyValuesEnum, ValidityQueryValuesEnum, MessageTypeQueryValuesEnum } from '@enums'
import { processKeywordsSearch } from '@common/entities/messages/utils'

export const DEFAULT_DATA: HistoryMessagesData = {
  page: 1,
  perPage: 50,
  totalPages: 0,
  totalResults: 0,
  messages: [],
}

const transformReqParams = (params: HistoryFormValues): string => {
  const {
    keywords,
    query_strategy,
    message_subtype,
    validity,
    message_type,
    indicator_id,
    last_two_years,
    page,
    sort,
  } = params
  const obj = {}

  if (keywords) set(obj, 'keywords', processKeywordsSearch(keywords))
  if (query_strategy && query_strategy !== QueryStrategyValuesEnum.ALL) set(obj, 'query_strategy', query_strategy)
  if (message_subtype && message_subtype !== ImTypeEnum.ALL) set(obj, 'message_subtype', message_subtype)
  if (validity && validity !== ValidityQueryValuesEnum.ALL) set(obj, 'validity', validity)
  if (message_type && message_type !== MessageTypeQueryValuesEnum.ALL) set(obj, 'message_type', message_type)
  if (indicator_id) set(obj, 'indicator_id', indicator_id)
  if (last_two_years) set(obj, 'last_two_years', last_two_years)

  set(obj, 'page', page)
  set(obj, 'sort', sort)

  return stringify(obj, { arrayFormat: 'brackets' })
}

const transformData = (data: GetHistoryMessagesSchema | null): HistoryMessagesData => {
  if (!data) return DEFAULT_DATA

  const transformedData = camelizeKeys<GetHistoryMessagesSchema>(data)

  return transformedData ?? DEFAULT_DATA
}

const queryFn = async (params: HistoryFormValues, signal: AbortSignal): Promise<HistoryMessagesData> => {
  let data: GetHistoryMessagesSchema | null = null
  const queryString = transformReqParams(params)
  const path = `/api/messages.json?${queryString}`
  const res = await fetch(path, { signal })

  if (res.ok) {
    data = await res.json()
  } else {
    displayError('Could not fetch history messages this time.')
  }

  return transformData(data) ?? { ...DEFAULT_DATA }
}

const useGetHistoryMessages = (params: HistoryFormValues) => {
  return useQuery<HistoryMessagesData>({
    initialData: DEFAULT_DATA,
    queryKey: ['GET_HISTORY_MESSAGES'],
    queryFn: ({ signal }) => queryFn(params, signal),
    refetchOnMount: false,
    retry: false,
    enabled: false,
  })
}

export default useGetHistoryMessages
