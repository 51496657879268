import { ImFormModeEnum } from '@enums'
import { ImFormBundleLocationData, ImPageState } from '@types'

export const preloadedStateDefaults: ImPageState = {
  formMode: ImFormModeEnum.FORM_MODE_CREATE,
  country: {
    data: {
      indicator: null,
      indicatorMessageCountry: {},
      newsCountries: [],
    },
  },
  location: {
    data: { indicator: null },
    updateMap: false,
    fitMapBounds: false,
  },
  supplier: {
    data: {
      alertForm: {
        selectedIndicator: null,
        suppliers: [],
        availableSuppliers: [],
        searches: [],
      },
      newsForm: {
        suppliers: [],
        availableSuppliers: [],
        searches: [],
      },
    },
    priorityForm: null,
  },
  duplicateDetection: {
    filters: {
      keywords: [],
    },
  },
}

export const locationDataDefault: ImFormBundleLocationData = {
  radius: null,
  selectedLocations: [],
  isPolygon: false,
  polygonPoints: null,
  polygonArea: null,
}
