import { IndicatorMessageFormValues } from '@types'
import { createContext, useContext, useState, ReactNode } from 'react'

type IMFormValues = IndicatorMessageFormValues | null

type TIMContext = {
  imFormValues: IMFormValues
  setIMFormValues: (values: IMFormValues) => void
  isValid: boolean
  setIsValid: (isValid: boolean) => void
}

const IMContext = createContext<TIMContext>({} as TIMContext)

type Props = {
  children: ReactNode
}

export const IMContextProvider = ({ children }: Props) => {
  const [imFormValues, setIMFormValues] = useState<IMFormValues>(null)
  const [isValid, setIsValid] = useState<boolean>(false)

  return (
    <IMContext.Provider
      value={{
        imFormValues,
        setIMFormValues,
        isValid,
        setIsValid,
      }}
    >
      {children}
    </IMContext.Provider>
  )
}

export const useIMContext = () => {
  return useContext(IMContext)
}
