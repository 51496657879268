import { useMutation, useQueryClient } from '@tanstack/react-query'

import { fetch, displayError } from '@services'
import type { HistoryMessagesData, Message } from '@types'
import { decamelizeKeys } from 'humps'

type UpdateMessageData = { id: number; message: Partial<Message>; willNavigateAfterCompletion?: boolean }

const useUpdateMessage = () => {
  const queryClient = useQueryClient()

  const mutationFn = async ({ id, message, willNavigateAfterCompletion }: UpdateMessageData) => {
    const messageSchema = decamelizeKeys(message)
    const res = await fetch(`/api/messages/${id}`, {
      body: JSON.stringify({
        message: {
          ...messageSchema,
          valid_until: message?.validUntil || '11-11-2222',
        },
      }),
      method: 'PATCH',
    })

    if (!res.ok) {
      displayError('Could not update message this time.')
      return Promise.reject()
    } else if (!willNavigateAfterCompletion) {
      await queryClient.setQueryData(['GET_HISTORY_MESSAGES'], (data: HistoryMessagesData): HistoryMessagesData => {
        return {
          ...data,
          messages: data.messages.map((messageData) =>
            messageData.id === id ? { ...messageData, ...message } : messageData
          ),
        }
      })
    }
  }

  return useMutation<void, void, UpdateMessageData>({
    mutationKey: ['UPDATE_MESSAGE'],
    mutationFn,
  })
}

export default useUpdateMessage
