import { has } from 'lodash'
import I18n from 'i18next'
import { Country, ImPageStateCountry, ImPageStateIndicator, ImValidationResult } from '@types'
import { Camelized } from 'humps'
import { imValidationValidResult } from '../constants'

const validateIndicatorMessagesAndNews = (
  indicator: ImPageStateIndicator | null,
  indicatorMessageCountry: Country | Record<string, never>,
  newsCountries: Country[]
): ImValidationResult => {
  const indicatorValid = has(indicator, 'id') && has(indicator, 'name')
  const indicatorMessageValid = has(indicatorMessageCountry, 'id') && has(indicatorMessageCountry, 'name')
  const partiallyValid = indicatorValid !== indicatorMessageValid
  const newsValid = newsCountries.length > 0
  const isValid = partiallyValid ? false : (indicatorValid && indicatorMessageValid) || newsValid
  const errors = {
    indicator: indicatorValid ? '' : I18n.t('common.messages.validation.required'),
    indicatorMessage: indicatorMessageValid ? '' : I18n.t('common.messages.validation.required'),
    news: newsValid ? '' : I18n.t('common.messages.validation.required'),
  }
  return isValid ? { valid: isValid, errors: {} } : { valid: isValid, errors }
}

const validateNews = (newsCountries: Camelized<Country>[]): ImValidationResult => {
  const newsValid = newsCountries.length > 0
  const message = {
    news: newsValid ? '' : I18n.t('common.messages.validation.required'),
  }
  return newsValid
    ? imValidationValidResult
    : {
        valid: newsValid,
        errors: message,
      }
}

export const validationCountry = (data: ImPageStateCountry, onlyNews: boolean): ImValidationResult => {
  const { indicator, indicatorMessageCountry, newsCountries } = data
  return onlyNews
    ? validateNews(newsCountries)
    : validateIndicatorMessagesAndNews(indicator, indicatorMessageCountry, newsCountries)
}
