import { ImTypeEnum, ValidityQueryValuesEnum, QueryStrategyValuesEnum, MessageTypeQueryValuesEnum } from '@enums'

export const initFilterConfig = {
  storageKey: 'ric.history',
  searchParams: [
    {
      key: 'keywords',
      defaultValue: '',
    },
    {
      key: 'query_strategy',
      defaultValue: QueryStrategyValuesEnum.SUBJECT_BODY,
      whitelist: [
        QueryStrategyValuesEnum.ALL,
        QueryStrategyValuesEnum.SUBJECT_BODY,
        QueryStrategyValuesEnum.ID_IN_ADMIN,
      ],
    },
    {
      key: 'message_subtype',
      defaultValue: ImTypeEnum.ALL,
      whitelist: [ImTypeEnum.ALL, ImTypeEnum.BUSINESS_PARTNER, ImTypeEnum.LOCATION, ImTypeEnum.COUNTRY],
    },
    {
      key: 'validity',
      defaultValue: ValidityQueryValuesEnum.ALL,
      whitelist: [ValidityQueryValuesEnum.ALL, ValidityQueryValuesEnum.EXPIRE_TODAY, ValidityQueryValuesEnum.VALID],
    },
    {
      key: 'message_type',
      defaultValue: MessageTypeQueryValuesEnum.ALL,
      whitelist: [
        MessageTypeQueryValuesEnum.ALL,
        MessageTypeQueryValuesEnum.INDICATOR_MESSAGE,
        MessageTypeQueryValuesEnum.NEWS,
      ],
    },
    {
      key: 'indicator_id',
      defaultValue: null,
    },
    {
      key: 'last_two_years',
      defaultValue: false,
      whitelist: [false, true],
    },
    { key: 'page', defaultValue: 1, initialValue: 1 },
    { key: 'sort', defaultValue: '-created_at' },
  ],
}
