import { ImTypeEnum } from '@enums'
import type { EditMsgReqBody, EditMsgReqData, EditMsgReqMessage, ImPageState, IndicatorMessageFormValues } from '@types'
import { decamelizeKeys } from 'humps'
import { transformSupplier } from '../transform-supplier'

export const getMsgReqBody = (imFormValues: IndicatorMessageFormValues, state: ImPageState): EditMsgReqBody => {
  const {
    subject,
    type,
    onlyNews,
    sourceForContent: sourceContent,
    sourceForCopyright: sourceCopyright,
    content: body,
    sourceUrl,
    industries,
  } = imFormValues

  const {
    country: { data: countryData },
    supplier: {
      data: {
        alertForm: { selectedIndicator },
      },
      priorityForm,
    },
  } = state

  const message: EditMsgReqMessage = {
    subject,
    sourceContent,
    sourceCopyright,
    body,
    hyperlinks: [sourceUrl],
    industries,
    indicatorId: null,
    formPriorityReverse: priorityForm === 'newsForm',
  }

  if (type === ImTypeEnum.COUNTRY && onlyNews) {
    message.country = { newsCountries: countryData.newsCountries.map(({ code }) => ({ code })) }
  } else if (type === ImTypeEnum.BUSINESS_PARTNER) {
    message.businessPartner = transformSupplier(state, onlyNews)
  }
  if (!onlyNews) {
    message.indicatorId = selectedIndicator?.id ?? null
  }

  return decamelizeKeys<EditMsgReqData>({ message })
}
