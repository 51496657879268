import { IndicatorMessageFormValues } from '@types'

export const IM_FORM_DEFAULT_VALUES = {
  onlyNews: false,
  type: null,
  industries: [],
  subject: '',
  sourceForContent: '',
  sourceForCopyright: '',
  sourceUrl: '',
  content: '',
  validUntil: null,
  noValidity: false,
} as IndicatorMessageFormValues
