import { some, includes, isEmpty, pickBy } from 'lodash'
import I18n from 'i18next'
import { ImFormSupplierData, ImValidationResult } from '@types'
import { imValidationValidResult } from '../constants'

const t = (name: string) => I18n.t(`common.messages.validation.${name}`)

const validateOnlyNews = ({ newsForm }: ImFormSupplierData): ImValidationResult => {
  const noNewsSuppliers = isEmpty(newsForm.suppliers)
  return noNewsSuppliers
    ? { valid: false, errors: { noNewsSuppliers: t('supplier.suppliers_empty') } }
    : imValidationValidResult
}

const validateIMAndNews = ({ alertForm, newsForm }: ImFormSupplierData, noValidity: boolean): ImValidationResult => {
  const noAlertSuppliers = isEmpty(alertForm.suppliers)
  const noIndicator = isEmpty(alertForm.selectedIndicator)
  const newsSuppliersStringified = newsForm.suppliers.map((newsSupplier) => JSON.stringify(newsSupplier))
  const sameSupplierExists = some(alertForm.suppliers, (alertSupplier) =>
    includes(newsSuppliersStringified, JSON.stringify(alertSupplier))
  )
  const bankruptcyWithValidityDate =
    !noIndicator && !noValidity && alertForm.selectedIndicator?.eventType === 'bankruptcy'
  const isValid = !noAlertSuppliers && !sameSupplierExists && !noIndicator && !bankruptcyWithValidityDate

  return isValid
    ? imValidationValidResult
    : ({
        valid: false,
        errors: pickBy({
          noAlertSuppliers: noAlertSuppliers && t('supplier.suppliers_empty'),
          sameSupplierExists: sameSupplierExists && t('supplier.same_supplier_exists'),
          noIndicator: noIndicator && t('required'),
          bankruptcyWithValidityDate: bankruptcyWithValidityDate && t('supplier.bankruptcy_with_validity_date'),
        }),
      } as ImValidationResult)
}

const isAlertFormEmpty = ({ alertForm }: ImFormSupplierData): boolean => {
  return isEmpty(alertForm.suppliers) && !alertForm.selectedIndicator
}

export const validationSupplier = (supplierData, onlyNews = false, noValidity = false): ImValidationResult => {
  return onlyNews || isAlertFormEmpty(supplierData)
    ? validateOnlyNews(supplierData)
    : validateIMAndNews(supplierData, noValidity)
}
