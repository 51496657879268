import { pick, uniqueId } from 'lodash'

import { getAvailableSuppliersFromSearchResults } from './getAvailableSuppliers'
import { priorityFormTypeEnum } from '@enums'
import type {
  Im,
  ImBundle,
  ImData,
  ImFormBundleLocationData,
  ImPageState,
  ImIndicator,
  ImPageStateIndicator,
  ImTarget,
  Country,
} from '@types'
import { ImFormModeEnum, ImTypeEnum } from '@enums'
import { isImBundleTargetLocation, isImTargetLocation, isImTypeEnum } from '@common/utils/type_guards'
import { Camelized } from 'humps'
import { locationDataDefault, preloadedStateDefaults } from '../constants'

const mapTargetsToLocationData = (targets: ImTarget[]): ImFormBundleLocationData => {
  const locationData = Object.assign({}, structuredClone(locationDataDefault))
  if (targets.length > 0) {
    const target = targets[0]
    if (isImBundleTargetLocation(target)) {
      locationData.polygonPoints = target.coordinates[0].map((point) => ({ longitude: point[0], latitude: point[1] }))
      locationData.selectedLocations = [{ name: target.name, fullName: target.name, latitude: 0, longitude: 0 }]
      locationData.isPolygon = true
      locationData.polygonArea = target.area * 1000000
    } else {
      targets.forEach((target) => {
        if (isImTargetLocation(target)) {
          locationData.selectedLocations.push({
            id: uniqueId(),
            ...pick(target, ['name', 'fullName', 'latitude', 'longitude']),
          })
        }
      })

      locationData.radius = isImTargetLocation(target) ? target.radius : null
    }
  }
  return locationData
}

const updatePreloadedStateWithIndicatorData = (data: ImPageState, indicatorData: ImIndicator) => {
  const indicator = indicatorData ? transformImIndicatorToImPageStateIndicator(indicatorData) : null
  data.country.data.indicator = indicator
  data.location.data = { ...data.location.data, indicator }
  data.supplier.data.alertForm.selectedIndicator = indicator
}

const updatePreloadedStateWithLocationData = (data: ImPageState, targets: ImTarget[]) => {
  const locationData = mapTargetsToLocationData(targets)
  data.location = {
    data: { ...locationData, ...data.location.data },
    updateMap: locationData && locationData.selectedLocations && locationData.selectedLocations.length > 0,
    fitMapBounds: locationData && locationData.selectedLocations && locationData.selectedLocations.length > 0,
  }
}

const updatePreloadedStateWithMessageState = (data: ImPageState, message: Im) => {
  data.formMode = ImFormModeEnum.FORM_MODE_EDIT
  if (message.isNews && message.type === ImTypeEnum.COUNTRY) {
    data.country.data.newsCountries = message.targets as Camelized<Country>[]
  }
  if (message.isNews && message.type === ImTypeEnum.BUSINESS_PARTNER) {
    data.supplier.data.newsForm.suppliers = message.targets
    data.supplier.data.newsForm.searches = message.searches
  }
}

const updatePreloadedStateWithBundleState = (data: ImPageState, messageBundle: ImBundle) => {
  data.formMode = ImFormModeEnum.FORM_MODE_REVIEW
  const alertMessage = messageBundle.messages.find((message) => message.isNews === false)
  const newsMessage = messageBundle.messages.find((message) => message.isNews === true)
  if (newsMessage) {
    data.country.data.newsCountries = newsMessage.targets as Camelized<Country>[]
    data.supplier.data.newsForm.suppliers = newsMessage.targets
    data.supplier.data.newsForm.searches = newsMessage.searches

    // it should have the same value in alertMessage.Since by default
    // alertForm is priorityForm, it only makes sense to check it,
    // if there is newsMessage.
    data.supplier.priorityForm = newsMessage.formPriorityReverse
      ? priorityFormTypeEnum.NEWS_FORM
      : priorityFormTypeEnum.ALERT_FORM
    updatePreloadedStateWithIndicatorData(data, newsMessage.indicator)
  }
  if (alertMessage) {
    const { targets } = alertMessage
    const [indicatorMessageCountry] = targets
    data.country.data.indicatorMessageCountry = indicatorMessageCountry as Camelized<Country>
    data.supplier.data.alertForm.suppliers = targets
    data.supplier.data.alertForm.searches = alertMessage.searches
    updatePreloadedStateWithLocationData(data, targets)
    updatePreloadedStateWithIndicatorData(data, alertMessage.indicator)
  }

  data.supplier.data.alertForm.availableSuppliers = getAvailableSuppliersFromSearchResults(
    data.supplier.data.alertForm.searches
  )
  data.supplier.data.newsForm.availableSuppliers = getAvailableSuppliersFromSearchResults(
    data.supplier.data.newsForm.searches
  )
}

const updatePreloadedStateWithCopiedMessageState = (data: ImPageState, copyMessage: Im) => {
  const { targets } = copyMessage
  data.formMode = ImFormModeEnum.FORM_MODE_COPY
  if (copyMessage.isNews) {
    data.country.data.newsCountries = targets as Camelized<Country>[]
    data.supplier.data.newsForm.suppliers = targets
    data.supplier.data.newsForm.searches = copyMessage.searches
  } else {
    const [indicatorMessageCountry] = targets
    data.country.data.indicatorMessageCountry = indicatorMessageCountry as Camelized<Country>
    data.supplier.data.alertForm.suppliers = targets
    data.country.data.indicatorMessageCountry = indicatorMessageCountry as Camelized<Country>
    data.supplier.data.alertForm.searches = copyMessage.searches
    updatePreloadedStateWithLocationData(data, targets)
    updatePreloadedStateWithIndicatorData(data, copyMessage.indicator)
  }
}

export const transformImIndicatorToImPageStateIndicator = (imIndicator: ImIndicator): ImPageStateIndicator => {
  return {
    id: imIndicator.id,
    name: imIndicator.name,
    type: isImTypeEnum(imIndicator.indicatorType) ? imIndicator.indicatorType : null,
    eventType: imIndicator.code,
  }
}

export const buildPreloadedState = (data: ImData): ImPageState => {
  const preloadedState = Object.assign({}, structuredClone(preloadedStateDefaults))
  const { message, messageBundle, copyMessage, currentUser, alertMessage } = data

  if (message) {
    updatePreloadedStateWithMessageState(preloadedState, message)
  }

  if (messageBundle) {
    updatePreloadedStateWithBundleState(preloadedState, messageBundle)
  }

  if (copyMessage) {
    updatePreloadedStateWithCopiedMessageState(preloadedState, copyMessage)
  }

  // read the casing for subject and body here.

  return {
    ...preloadedState,
    currentUser,
    duplicateDetection: {
      filters: {
        keywords: [],
      },
      alertMessage,
    },
  }
}
