import { shape, string, number, oneOfType } from 'prop-types'

export const researchSource = shape({
  id: number.isRequired,
  name: string.isRequired,
})

export const indicator = shape({
  id: oneOfType([number, string]).isRequired,
  type: string,
  name: string.isRequired,
  eventType: string,
})

export const location = shape({
  fullName: string,
  name: string.isRequired,
  latitude: number.isRequired,
  longitude: number.isRequired,
})

export const position = shape({
  latitude: number.isRequired,
  longitude: number.isRequired,
})

export const businessPartner = shape({
  id: oneOfType([string, number]).isRequired,
  addtributes: shape({
    name: string.isRequired,
    address: shape({
      city: string.isRequired,
      country: string.isRequired,
    }),
  }),
})

export const exclusionItem = shape({
  id: number,
  name: string,
  alternatives: Array,
  description: string,
  isNotString: string,
  isNotName: Array,
})

export const hitsAssigneeShape = shape({
  id: oneOfType([string, number]),
  uniqueHitsCount: number,
  totalHitsCount: number,
  rangeStart: string,
  rangeEnd: string,
})

export const userShape = shape({
  fullName: string,
  id: oneOfType([number, string]).isRequired,
})
