import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Divider, Stack, Typography } from '@mui/material'

import { useIMContext } from '@hooks'
import type { ImAction } from '@types'
import type { ImTypeEnum } from '@enums'
import { useClearDataOnLeavingReviewPage } from '@hooks'

import { CreateIMForm, DataIMForm, IMTypeSpecificForm } from './components'
import { PAGE_HEADER_TEXT } from './constants/page-header/page-header'

type Props = {
  action?: ImAction
  imType?: ImTypeEnum | null
  imId?: number | null
  articleId?: number | null
}

const IMPage = ({ action = 'create', imType = null, imId = null, articleId = null }: Props) => {
  const history = useHistory()
  const { imFormValues } = useIMContext()

  useClearDataOnLeavingReviewPage(action)

  useLayoutEffect(() => {
    if (imType && !imFormValues) {
      if (action === 'create') {
        history.replace(articleId ? `/im/create?article_id=${articleId}` : '/im/create')
      } else if (action === 'review') {
        history.replace('/review')
      } else {
        history.replace('/history')
      }
    }
  }, [])

  return (
    <Stack sx={{ pt: 2, px: 3 }}>
      {!imType ? (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            {PAGE_HEADER_TEXT[action]}
          </Typography>
          <Divider />
        </>
      ) : null}
      {!imType ? (
        <Box mt={10}>
          {action === 'create' ? (
            <CreateIMForm />
          ) : (
            <DataIMForm action={action} imId={imId} defaultValues={imFormValues} />
          )}
        </Box>
      ) : imFormValues ? (
        <IMTypeSpecificForm action={action} imType={imType} imId={imId} articleId={articleId} />
      ) : null}
    </Stack>
  )
}

export default IMPage
